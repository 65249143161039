import React from 'react';
import Navbar from './Navbar';
import { Wrapper, Overlay } from './styles';

export const Header = () => (
  <Wrapper>
    <Overlay />
    <Navbar />
  </Wrapper>
);
