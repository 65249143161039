module.exports = {
  defaultTitle: 'Lewis Vickers',
  logo: 'https://vickerslewis.co.uk/favicon/favicon-512.png',
  author: 'Lewis Vickers',
  url: 'https://vickerslewis.co.uk',
  legalName: 'Lewis Vickers',
  defaultDescription: 'I’m Lewis and I’m a Software Engineer',
  socialLinks: {
    twitter: 'http://www.twitter.com/vickerslewis',
    github: 'https://github.com/vickerslewis',
    linkedin: 'https://www.linkedin.com/in/vickerslewis/',
    instagram: 'https://instagram.com/vickerslewis',
    youtube: 'https://www.youtube.com/user/vickerslewis',
    google: 'https://plus.google.com/u/0/vickerslewis',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@vickerslewis',
  },
  address: {
    city: 'Manchester',
    region: 'Yorkshire',
    country: 'England',
    zipCode: 'M1 2EY',
  },
  contact: {
    email: 'lewis@vickerslewis.co.uk',
    phone: '07826855567',
  },
  foundingDate: '1996',
};
