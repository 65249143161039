import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Flex, Links, Details } from './styles';
import social from './social.json';

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2>Lewis Vickers</h2>
        <span>VickersLewis | {new Date().getFullYear()}</span>
      </Details>
      <Links>
        {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
        <a
          href="mailto:lewis@vickerslewis.co.uk"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="email me at lewis@vickerslewis.co.uk"
        >
          <img width="24" src="/icons/email.svg" alt="Email icon" />
        </a>
      </Links>
    </Flex>
  </Wrapper>
);
